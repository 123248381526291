import styled from "styled-components"
import { regular12, regular14, regular16, bold16, bold18, bold24 } from "../../styledComponents/typography"
import breakpoints from "../../styledComponents/breakpoints"
import { baseGridStyles, strikethrough } from "../../styledComponents/base";



const StyledEventPrimaryCard = styled.article`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  align-content: stretch;
  position: relative;
  background-color: #fff;
  border-radius: 4px;
  padding-bottom: 1.6rem;

  @media screen and (min-width: ${breakpoints.md}px) {
    min-height: 34rem;
  }

  &:hover {
    box-shadow: 0 4px 15px 0 rgb(40 44 53 / 6%), 0 2px 2px 0 rgb(40 44 53 / 8%);
  }

  p {
    margin: 0;
    padding-bottom: 1.6rem;
  }

  a {
    ${baseGridStyles}
    padding: 0;
    grid-row-gap: 0;
   
    @media screen and (min-width: ${breakpoints.sm}px) {
      padding: 0 1.6rem;
      grid-row-gap: 0;
    }

    &:hover {
  
      button {
        background: var(--light-purple-darkest);
        cursor: pointer;
      }
    }

    button {
      width: calc(100% - 3.2rem);
      grid-column: 1 / 5;
      @media screen and (min-width: ${breakpoints.sm}px) {
        grid-column: 2 / 7;
        width: 100%;
      }
      @media screen and (min-width: ${breakpoints.md}px) {
        grid-column: 3 / 12;
        margin-top: 1.6rem;
      }
    }

  }

  .start-date-time {
    ${regular14}
    grid-column: 2 / 5;
    
    @media screen and (min-width: ${breakpoints.sm}px) {
      grid-column: 2 / 7;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
      grid-column: 3 / 12;
    }
  }

  .end-date-time, .eventDescription {
    position: absolute;
    top: -50000px;
    left: -500000px;
    visability: hidden;
  }

  .event-location {
    grid-column: 2 / 5;
    ${regular14}

    @media screen and (min-width: ${breakpoints.sm}px) {
      grid-column: 2 / 7;
    }

    @media screen and (min-width: ${breakpoints.md}px) {
      grid-column: 3 / 13;
    }
  }

  .event-month {
    font-size: 1.5rem;
    line-height: 1rem;
    text-transform: uppercase;
    color: #E03210;
    margin-top: 0.4rem;
}
  }
  .event-day {
    text-align: center;
    font-weight: 500;
    font-size: 1.8rem;
    margin-top: 0.5rem;
    padding-bottom: 0.5rem;
    color: var(--black);
  }

  .event-price {
    position: absolute;
    top: 2.2rem;
    left: 0;
    background-color: var(--white);
    color: var(--light-purple-darkest);
    ${regular12}
    border: 1px solid var(--white);
    border-left:0;
    padding: 0.2rem 0.6rem;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 700;

    span.hidden {
      display: none;
    }
  }
  .event-fullprice {
    ${strikethrough};
    // color: var(--light-purple-dark);
  }
`

const EventHeader = styled.h2`
  color: var(--grey-darker);
  margin: 0.8rem 0 0;
  ${bold18}
  color: var(--light-purple-darker);
  grid-column: 2 / 5;
  text-align: left;
  @media screen and (min-width: ${breakpoints.sm}px) {
    margin: 0.8rem 0 0;
    grid-column: 2 / 7;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    grid-column: 3 / 13;
  }

  a, abbr[title] { {
    text-decoration: none;
  }
`

const EventFigure = styled.figure`
  grid-column: span 4;
  margin: 0;
  padding: 0;
  position: relative;

  @media screen and (min-width: ${breakpoints.sm}px) {
    grid-column: span 6;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    grid-column: span 12;
  }
  &:hover img {
      opacity: 0.8;
    }
  &:first-child {
    @media screen and (min-width: ${breakpoints.sm}px) {
      margin-left: -1.6rem;
      margin-right: -1.6rem;
    }  
  }
`

const EventFigureCaption = styled.figcaption`
  color: var(--white);
  font-size: 1.6rem;
  font-weight: 700;
  position: absolute;
  bottom: 0;
  left: 0;
  color: var(--white);
  font-size: 1.6rem;
  font-weight: 700;
  padding: 8rem 3rem 1.5rem 1.5rem;
  margin: 0;
  height: auto;
  width: 100%;
  background: linear-gradient(0deg,#000 0%,#00111400 100%);
  text-align: left;
  ${props => props.type === 'command-heaing' && `
  &:after {
    background-image: url(/images/backgrounds/kaye-lee.png?v3);
    background-repeat: no-repeat;
    background-position: right top;
    background-size: 80%;
    content: '';
    position: absolute;
    right: 1.6rem;
    bottom: 1.6rem;
    width: 7rem;
    height: 7rem;
    @media screen and (min-width: ${breakpoints.sm}px) {
      background-position: right bottom;
      background-size: 80%;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
      background-position: right bottom;
    }
  }
  `}
  span {
    position: relative;
    margin-left: 0.8rem;
  }
  svg {
    position: relative;
    top: 5px;
    display: inline-block;
  }
`

const EventDate = styled.div`
  display: block;
  grid-column: 1 / 4;
  color: var(--black);
  text-align: left;
`

const EventLocation = styled.div`
  display: block;
  text-align: left;
  color: var(--black);

  span.hidden {
    display: none;
  }
`

const EventDuration = styled.div`
  display: block;
`
const EventCalendarCard = styled.div`
  margin-top: 1.6rem;
  grid-row: 2 / span 2;
  text-align: center;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  span {
    display: block;
  }

  @media screen and (min-width: ${breakpoints.md}px) {
    grid-column: 1 / 3;
  }
`

const StyledEventSingleCard = styled.article`
  grid-column: span 4;
  position: relative;
  background-color: var(--grey-white);
  padding: ${props => props.isLander ? '0' : '0 '};
  @media screen and (min-width: ${breakpoints.sm}px) {
    grid-column: span 6;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    grid-column: span 12;
    padding: 0;
    ${props => props.isLander && 'margin-top:3.6rem' };
    background-color: ${props => props.isLander ? 'var(--white)' : 'var(--grey-lightest)'};
  }


  & > span {
    ${baseGridStyles}
    padding: 0;
    grid-row-gap: 0;
   
    @media screen and (min-width: ${breakpoints.sm}px) {
      padding: 0 1.6rem;
      grid-row-gap: 0;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
      padding: 0;
    }

    &.url, span.url {
      display: none;
    }
  }
 
  .start-date-time {
    grid-column: 1 / 5;
    ${regular14}
    margin: 0.8rem 0 0;
    @media screen and (min-width: ${breakpoints.md}px) {
      grid-column: 8 / 13; 
      ${regular16}
    }
  }

  .event-location {
    grid-column: 1 / 5;
    ${regular14}
    margin: 0;
    @media screen and (min-width: ${breakpoints.md}px) {
      grid-column: 8 / 13; 
      ${regular16}
    }
  }

  .event-month {
    font-size: 1.5rem;
    line-height: 1rem;
    text-transform: uppercase;
    color: #E03210;
    margin-top: 0.4rem;
  }

  .event-day {
    text-align: center;
    font-weight: 500;
    font-size: 1.8rem;
    margin-top: 0.5rem;
    padding-bottom: 0.5rem;
    color: var(--black);
  }

  .event-price {
    ${regular14}
    margin: 0.8rem 0 0.8rem;
    grid-column: 1 / 5;
    @media screen and (min-width: ${breakpoints.md}px) {
      grid-column: 8 / 13; 
      ${regular16}
    }
    strong.sale {
      background-color: var(--green-strong);
      color: var(--white); 
      padding-right: 0.8rem;
      padding-left: 0.8rem;
      padding-top: 0.4rem;
      padding-bottom: 0.4rem;
      margin-top: 0.4rem;
      margin-bottom: 0.4rem;
      margin-right: 0.8rem;
      display: inline-block;
    }
    span.hidden {
      display: none;
    }
    .daynotice {
      ${regular12}
    }
  }

  .react-tabs__tab-panel {
    strong.sale {
      margin-right: 0.8rem;
    }
  }

`

const StyledEventSingleFigure = styled.figure`
  grid-column: span 4;
  padding: 0;
  position: relative;
  background: var(--black);
  &:first-child {
    margin: 0 -1.6rem;
    @media screen and (min-width: ${breakpoints.sm}px) {
      grid-column: span 6;
      margin: 0 -3.2rem;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
      grid-column: span 7;
      grid-row: span 7;
      margin: 0;
    }
  }
`

const StyledEventSingleCalendarCard = styled.div`
  width: 50px;
  background: #fff;
  border: 1px solid #ddd;
  padding-top: 1rem;
  text-align: center;
  padding: 1rem 0 0;
  margin-bottom: 2rem;
  margin-top: 0;
  margin-right: 1.5rem;
  height: 58px;
  position: absolute;
  top: 1.6rem;
  left: 0rem;
  margin-top: 0.2rem;
  grid-row: 2 / span 3;
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${breakpoints.md}px) {
    left: 2.4rem;
    top: 2.4rem;
  }
  @media screen and (min-width: ${breakpoints.l}px) {
    left: 3.2rem;
    top: 3.2rem;
  }
  a {
    border-bottom: 0;
  }
  span {
    display: block;
  }
`

const StyledEventSingleHeader = styled.h1`
  color: var(--grey-darker);
  margin: 0.8rem 0 0;
  ${bold18}
  color: var(--light-purple-darker);
  grid-column: 1 / 5;
  @media screen and (min-width: ${breakpoints.sm}px) {
    margin: 0.8rem 0 0;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    grid-column: 8 / 13;
    ${bold24}
    margin-top: 2.4rem;
  }

  a, abbr[title] {
    text-decoration: none;
  }
`

const StyledEventSingleExcerpt = styled.div`
  grid-column: 1 / 5; 
  @media screen and (min-width: ${breakpoints.md}px) {
    grid-column: 8 / 13; 
    margin-top: 1.6rem;
  }
  @media screen and (min-width: ${breakpoints.l}px) {
    margin-right: 1.6rem;
  }

  p {
    ${props => props.isLander ? regular14 : bold16};
    font-weight: ${props => props.isLander ? '900' : '100'};
    padding-right: 1.6rem;
  }

`

const StyledEventSingleDatesLander = styled.div`
  grid-column: 1 / 5; 
  @media screen and (min-width: ${breakpoints.md}px) {
    grid-column: 8 / 12; 
    margin-top: 1.6rem;
  }

  h2 {
    display: none;
  }

  ul {
    margin-bottom: 2rem;
    @media screen and (min-width: ${breakpoints.md}px) {
      margin-top: 0;
    }
    @media screen and (min-width: ${breakpoints.l}px) {
      margin-top: 0;
      margin-left: 3.2rem;
    }
  }
`

const StyledEventBookingButtons = styled.div`
  grid-column: 1 / 5; 
  @media screen and (min-width: ${breakpoints.md}px) {
    grid-column: 8 / 13; 
  }

  @media screen and (min-width: ${breakpoints.l}px) {
    ${props => props.type && "margin-top: 1.6rem"};
  }

  p.notice {
    ${regular14}
    margin-top: 1.6rem;
  }
  svg.external {
    width: 2rem;
  }
`

const StyledEventBookingButtonsLander = styled.div`
  grid-column: 1 / 5; 
  @media screen and (min-width: ${breakpoints.sm}px) {
    grid-column: 2 / 6; 
    margin-bottom: 4rem;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    grid-column: 8 / 12; 
  }
`

const StyledEventBookingPassed = styled.div`
  grid-column: 1 / 5; 
  @media screen and (min-width: ${breakpoints.md}px) {
    grid-column: 8 / 12; 
  }

  p {
    margin-top: 1.6rem;
  }
`

const StyledPaymentIcons = styled.div `
  text-align: center;
  @media screen and (min-width: ${breakpoints.md}px) {
    text-align: left;
    margin-top: 1.6rem;
  }
`

export {
  StyledEventPrimaryCard,
  EventHeader,
  EventFigure,
  EventFigureCaption,
  EventDate,
  EventLocation,
  EventDuration,
  EventCalendarCard,
  StyledEventSingleCard,
  StyledEventSingleFigure,
  StyledEventSingleCalendarCard,
  StyledEventSingleHeader,
  StyledEventSingleExcerpt,
  StyledEventSingleDatesLander,
  StyledEventBookingButtons,
  StyledEventBookingButtonsLander,
  StyledEventBookingPassed,
  StyledPaymentIcons
}
